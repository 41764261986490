<template>
  <v-container class="px-0">
    <PromoOverviewHeader />
    <PromoOverviewMetrics />
    <v-row>
      <v-col cols="12">
        <v-row class="mb-4">
          <v-col cols="12" md="6">
            <LineChartArea />
          </v-col>
          <v-col cols="12" md="6">
            <MROICurve />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <HorizontalBarArea />
          </v-col>
          <v-col cols="12" md="6">
            <EfficiencyBarChart />
          </v-col>
          <v-col cols="12" md="6">
            <ImpactSpendBarChart />
          </v-col>
          <v-col cols="12">
            <SalesTable />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PromoOverview",
  components: {
    PromoOverviewHeader: () =>
      import("@/views/results/promo-overview/PromoOverviewHeader"),
    PromoOverviewMetrics: () =>
      import("@/views/results/promo-overview/PromoOverviewMetrics"),
    LineChartArea: () => import("@/views/results/promo-overview/LineChartArea"),
    MROICurve: () => import("@/views/results/promo-overview/MROICurve"),
    HorizontalBarArea: () =>
      import("@/views/results/promo-overview/HorizontalBarArea"),
    SalesTable: () => import("@/views/results/promo-overview/SalesTable"),
    EfficiencyBarChart: () =>
      import("@/views/results/promo-overview/EfficiencyBarChart"),
    ImpactSpendBarChart: () =>
      import("@/views/results/promo-overview/ImpactSpendBarChart"),
  },
};
</script>

<style scoped></style>
